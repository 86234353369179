.Reveal {
  --delay: 0.05s;
  --stagger: 0.08s;
  --index: 0;
}

.Reveal:not(.Reveal--root),
.Reveal-child {
  opacity: 0.01;
  transform: translateY(2rem);
  transition: transform 0.8s cubic-bezier('easeOutQuart'), opacity 0.6s;
  transition-delay: calc(var(--index) * var(--stagger) + var(--delay));

}

html.ready .Reveal:not(.Reveal--root).is-inview,
html.ready .Reveal.is-inview .Reveal-child {
    opacity: 1;
    transform: none;
}

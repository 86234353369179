.FormBuilder {
  margin: 3em 0;
  // reset because .Prose is overwriting these values
  // font-family: $font-family-base;
  // line-height: $line-height-base;
  // @include font-size($font-size-base);
  // @include prop-res("font-size", $font-size-form);

  // custom style
  button:not(.dropdown-toggle) {
    // @extend .Button;
  }

  input[type="text"],
  input[type="email"],
  input[type="date"],
  textarea,
  select {
    @extend .form-control;
    &.focus-visible {
      background-color: rgba(#fff, .1);
    }
  }

  input[type="text"],
  input[type="email"],
  select,
  .dropdown-toggle {
    @include media-breakpoint-down(xs) {
      padding-top: 1.75rem;
      height: calc(1.5em + 1.75rem + .5rem + 1px);
    }
  }

  textarea {
    resize: none;
    @include media-breakpoint-down(xs) {
      border-top: 1.75rem solid $input-bg;
      height: auto;
      &:focus {
        border-top: 1.75rem solid #3a3939;
      }
    }
  }

  .InputfieldHidden {
    display: none;
  }

  .InputfieldStateError {
    input[type="text"],
    input[type="email"],
    textarea,
    select,
    .dropdown-toggle {
      // $color: $color-cta-primary;
      // border-color: $color;
      &:focus {
        // border-color: $color;
        // box-shadow: 0 0 0 1px $color;
      }
    }
    .InputfieldContent label {
      // color: $color-cta-primary;
    }
  }

  .input-error {
    @extend .form-text;
    @include font-size-small();
    // color: $color-cta-primary;
  }

  .notes {
    @include font-size-small();
    @extend .form-text;
    @extend .text-muted;
    margin-bottom: 0;
  }

  .Inputfields {
    @include make-row();
  }

  .Inputfield,
  fieldset {
    @extend .form-group;
    // @include grid-spacer("p", "x", 0.5);
    @include make-col-ready();
    // @include media-breakpoint-down(xs) {
    //   width: 100% !important;
    // }
  }

  .InputfieldStateRequired {
    label.InputfieldHeader,
    label .pw-no-select {
      &:after {
        content: ' *';
        // @include label();
        color: inherit;
      }
    }
  }

  .InputfieldCheckbox {
    .InputfieldHeader {
      display: none;
    }
  }

  .InputfieldCheckbox,
  .InputfieldCheckboxes,
  .InputfieldRadios {
    .InputfieldContent {
      label {
        @extend .form-check-label;
        &:not(.custom-control-label) {
          @extend .form-check;
        }
      }
      input {
        @extend .form-check-input;
        margin: 0 !important;
      }
    }
  }

  .InputfieldRadios {
    .InputfieldContent {
      input {
        margin-top: .25rem;
      }
    }
  }

  .InputfieldCheckbox,
  .InputfieldCheckboxes {
    .InputfieldContent {
      input {
        margin-top: .3rem;
      }
    }
  }

  .Inputfield_file {
    li + li {
      margin-top: $form-group-margin-bottom;
    }
  }

  .InputfieldContent {
    position: relative;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }

  // applied via js to make animated borders
  // that works even with autocomplete styles
  .InputfieldDeco {
    z-index: 2;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-top: 1px solid $input-border-color;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      border-top: 1px solid $input-focus-border-color;
      transform: translateZ(0) scaleX(0);
      transform-origin: 100% 0;
      transition: transform .6s cubic-bezier("easeInOutQuart");
    }
  }

  // hide useless deco
  .bootstrap-select + .InputfieldDeco {
  }
  .InputfieldCheckbox .InputfieldDeco {
    display: none;
  }

  input:focus,
  textarea:focus,
  .dropdown-toggle:focus,
  .dropdown.show, {
    & ~ .InputfieldDeco:after {
      transform: translateZ(0) scaleX(1);
      transform-origin: 0 0;
    }
  }

  .InputfieldSubmit {
    margin: $form-group-margin-bottom * 2 0;
    display: flex;
    .InputfieldContent {
    }
    button {
      @extend .Button;
    }
    .InputfieldDeco {
      display: none;
    }
  }


  .InputfieldHeader {
    position: absolute;
    z-index: 2;
    padding: .5rem 0 0 .5rem;
    pointer-events: none;
    @include media-breakpoint-up(sm) {
      padding-top: 0;
      padding-bottom: 0;
      width: 9rem;
      top: 0;
      bottom: 0;
      // height: $input-height;
      display: flex;
      align-items: center;
      margin: 0;
    }
  }

  .InputfieldTextarea .InputfieldHeader {
    @include media-breakpoint-up(sm) {
      bottom: unset;
      top: .6em;
    }
  }

  .InputfieldContent {
    input,
    textarea,
    .dropdown-toggle,
    .dropdown-menu a {
    }
    @include media-breakpoint-up(sm) {
      input,
      textarea,
      select,
      .dropdown-toggle,
      .dropdown-menu a {
        padding-left: 10rem;
      }
      input,
      textarea,
      .dropdown-toggle {
        &:not(:focus) {
          cursor: pointer;
        }
      }
    }
  }

  .InputfieldDatetimeButton {
    position: absolute;
    right: .5rem;
    top: .5rem;
    height: rem(24px);
    width: rem(24px);
    appearance: none;
    border: none;
    background-color: transparent;
    background-image: url(svg("calendar", "fill: none; stroke: #fff; stroke-width: 1.3px", true));
    background-size: 100% 100%;
    pointer-events: none;
    @include media-breakpoint-down(xs) {
      top: 1.5rem;
    }
  }

  .alert-success {
    background: #fff;
    border-radius: 4px;
    padding: 1rem;
    font-family: $font-family-sans-serif;
    color: $color-text;
  }
}

.FormBuilderErrors {
  background: #8f3030;
  color: #fff;
  border-radius: 4px;
  p {
    margin: 0;
  }
}

// .FormBuilderSubmitted {
//   font-family: $font-family-sans-serif;
// }

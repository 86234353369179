.fancybox-bg {
  background-color: #fff;
}
.fancybox-is-open .fancybox-bg {
  opacity: 1;
}

.fancybox-button {
  width: 3rem !important;
  height: 3rem !important;
  padding: 0 !important;
  color: color("turtledove") !important;
  background-color: transparent !important;
  @include media-breakpoint-down(xs) {
    width: 2rem !important;
    height: 2rem !important;
  }
}

.fancybox-navigation .fancybox-button {
  top: 50%;
  transform: translateY(-50%);
}

.fancybox-button.fancybox-button--arrow_right {
  right: 1rem;
}
.fancybox-button.fancybox-button--arrow_left {
  left: 1rem;
  svg {
    transform: scaleX(-1);
  }
}

.fancybox-button--close {
  margin: 1rem 1rem 0 0;
}

.fancybox-slide--image {
  padding: 5rem;
  @include media-breakpoint-down(xs) {
    padding: 3rem 0;
  }
}

.fancybox-navigation {
  @include media-breakpoint-down(xs) {
    display: none;
  }
}

.fancybox-button svg path {
  stroke-width: inherit;
  fill: inherit;
}

.fancybox-calendar {
  .fancybox-slide {
    padding: 0;
    overflow: hidden;
  }
  .fancybox-content {
    display: flex !important;
    position: absolute;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0;
  }
}


.Instagram {
  .Featured + & {
    border-top: 1px solid #fff;
    @include prop-res("border-top-width", $section-spacer);
  }
  // @include prop-res("margin-bottom", $section-spacer);
  position: relative;
  pointer-events: none;
  transform: translateZ(0);
  background-color: $color-background-dark;
  height: 60vw;
  overflow: hidden;
  @media (orientation: portrait) {
    height: 74.5vw;
  }
  @include media-breakpoint-down(xs) {
    height: 99.5vw;
  }
  // .has-scroll-smooth & {
  //   height: 45vw !important;
  // }
}


.Instagram-header {
  @include make-container();
  top: 0;
  position: absolute;
  color: #fff;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(rgba($color-background-dark, 1) 0%, rgba($color-background-dark, 0) 100%);
  padding-top: 1.5rem;
  padding-bottom: 3rem;
  h2 {
    margin: 0;
    span {
      font-weight: 700;
      font-family: $font-family-serif;
    }
  }
  @include media-breakpoint-up(xs) {
    padding-left: 0;
    padding-right: 0;
  }
  @include media-breakpoint-up(sm) {
    justify-content: space-between;
  }
}

.Instagram-cta {
  color: #fff !important;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  font-family: $font-family-sans-serif;
  font-weight: 700;
  font-size: 0.8rem;
  text-transform: uppercase;
  pointer-event: auto;
  .Arrow {
    margin-left: 1rem;
  }
  @include media-breakpoint-down(xs) {
    .Instagram-header & {
      display: none;
    }
  }
  @include media-breakpoint-up(sm) {
    .Instagram-footer & {
      visibility: hidden;
      pointer-event: none;
    }
  }
}

.Instagram-list {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  @include media-breakpoint-up(sm) {
    padding: 0 .5rem;
  }
}

.Instagram-col {
  position: absolute;
  top: 0;
  left: 0;
  width: 33.333%;
  @include media-breakpoint-up(sm) {
    width: 25%;
  }
  &:nth-child(2) {
    left: 33.333%;
    @include media-breakpoint-up(sm) {
      left: 25%;
    }
  }
  &:nth-child(3) {
    left: 66.666%;
    @include media-breakpoint-up(sm) {
      left: 50%;
    }
  }
  &:nth-child(4) {
    left: 75%;
    @include media-breakpoint-down(xs) {
      display: none;
    }
  }
  .has-scroll-smooth & {
    top: 50%;
    transform: translateY(-50%);
  }
}

.Instagram-items {
  display: flex;
  flex-direction: column-reverse;
  // transition: none;
  opacity: .9;
  .mobile .is-inview & {
    transition: transform 2s;
  }
  .Instagram-col:nth-child(2) &,
  .Instagram-col:nth-child(3) & {
    flex-direction: column;
  }
  html.has-scroll-smooth & {
    will-change: transform;
  }
  html:not(.has-scroll-smooth) .Instagram-col:nth-child(2) &,
  html:not(.has-scroll-smooth) .Instagram-col:nth-child(4) & {
    transform: translateY(-30%);
  }
  html.mobile:not(.has-scroll-smooth) .is-inview .Instagram-col:nth-child(1) &,
  html.mobile:not(.has-scroll-smooth) .is-inview .Instagram-col:nth-child(3) &,
  html:not(.mobile):not(.has-scroll-smooth) .Instagram-col:nth-child(1) &,
  html:not(.mobile):not(.has-scroll-smooth) .Instagram-col:nth-child(3) & {
    transform: translateY(-25%);
  }
  html.mobile:not(.has-scroll-smooth) .is-inview .Instagram-col:nth-child(2) &,
  html.mobile:not(.has-scroll-smooth) .is-inview .Instagram-col:nth-child(4) &,
  html:not(.mobile):not(.has-scroll-smooth) .Instagram-col:nth-child(2) &,
  html:not(.mobile):not(.has-scroll-smooth) .Instagram-col:nth-child(4) & {
    transform: translateY(-10%);
  }
}

.Instagram-item {
  height: 0;
  pointer-events: auto;
  padding-bottom: 100%;
  background-size: cover;
  background-position: center;
  position: relative;
  // backface-visibility: hidden;
  opacity: 0;
  will-change: transform;
  transition: opacity .2s;
  &.lazyloaded {
    opacity: 1;
  }
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border: .25rem solid $color-background-dark;
    @include media-breakpoint-up(sm) {
      border-width: .5rem;
    }
  }
  // margin-bottom: .5rem;
  // &:last-child {
  //   margin-bottom: 0;
  // }
  &:nth-child(n+6) {
    display: none;
  }
  .Instagram-col:nth-child(odd) & {
    &:nth-child(n+5) {
      display: none;
    }
  }
  html.has-scroll-smooth {
    display: block !important;
  }
}

.Instagram-footer {
  pointer-event: none;
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  @include make-container();
  padding-bottom: 1rem;
  padding-top: 3rem;
  background-image: linear-gradient(to top, rgba($color-background-dark, 1) 0%, rgba($color-background-dark, 0) 100%);
}

.Gallery {
  @include prop-res("padding-bottom", $section-spacer);
  background-color: $color-background;

  // .desktop & * {
  //   transition-timing-function: cubic-bezier('easeInOutQuart');
  // }

  .swiper-wrapper {
  //   cursor: grab;
  }

  .row {
    justify-content: center;
    position: relative;
  }
}

.Gallery-content {
  position: static;
  @include media-breakpoint-up(sm) {
    @include make-col(10);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(8);
  }
}

.Gallery-slide {
  overflow: hidden;
  background-color: shade(#fff, 10%);
  // opacity: 0;
  // transition: opacity .6s;
  // &.swiper-slide-active {
  //   opacity: 1;
  //   transition-duration: 0s;
  // }
}

.Gallery-slide-content {
  .desktop & {
    // transition-timing-function: cubic-bezier(easeInOutQuart);
  }
}

.Gallery-image {
  padding-bottom: 2/3 * 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0;
  transition: opacity .6s;
  &.lazyloaded {
    opacity: 1;
  }
}

.Gallery-nav {
  display: flex;
  justify-content: space-between;
  @include spacer("m","t");
  @include media-breakpoint-up(md) {
    @include spacer("m","t", 0);
  }
  .mobile &,
  .tablet & {
    display: none;
  }
}

.Gallery-button {
  // color: color("turtledove");
  color: $color-accent;
  cursor: pointer;
  appearance: none;
  border: none;
  font-size: 0;
  background-color: transparent;
  padding: 0;
  outline: none !important;
  transition: opacity .6s;
  &.swiper-button-disabled {
    pointer-events: none;
    opacity: 0;
  }
  // transition: transform .6s;
  // &:hover {
  //   transform: translateX(10px);
  // }

  @include media-breakpoint-up(md) {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  $position: (
    md: 0,
    lg: 10%
  );

  // svg {
  //   width: 50/16 * 1rem;
  //   height: 30/16 * 1rem;
  // }
  &--prev {
    svg {
      transform: scaleX(-1);
    }
    // &:hover {
    //   transform: translateX(-10px);
    // }
    @include prop-res(left, $position);
  }

  &--next {
    @include prop-res(right, $position);
  }
}

.Gallery-pagination {
  position: relative;
  bottom: 0 !important;
  @include spacer("m", "t", 3);
  .desktop & {
    display: none;
  }
}

.Gallery-caption {
  text-align: center;
  margin-top: 1rem;
  @include media-breakpoint-up(sm) {
    @include make-col(10);
  }
  @include media-breakpoint-up(md) {
    margin-top: 2rem;
  }
  @include media-breakpoint-up(lg) {
    @include make-col(8);
  }
  > *:first-child {
    // @include spacer("m", "t", 5);
  }
}

.Gallery-title {

}

.Gallery-description {
  margin: 0 auto;
  max-width: 40rem;
}

.TextCols--background  + .Gallery {
  @include prop-res("margin-top", $section-spacer, -1);
  position: relative;
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    top: 0;
    @include prop-res("height", $section-spacer, 0.5);
    background-color: color("sand");
  }
}

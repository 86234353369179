.NextArticle {
  background-color: #fff;
  padding: 4rem 0;
  a {
    text-decoration: none;
  }

  h1 {
    margin-bottom: 1rem;
  }

  .Arrow {
    margin-top: auto;
    color: $color-accent;
  }
}

.NextArticle-container {
  @include make-container();

  a {
    display: flex;
    flex-wrap: wrap;
  }
}

.NextArticle-media {
  flex: 0 0 100%;
  @include media-breakpoint-down(xs) {
    margin-bottom: 1.5rem;
  }
  @include media-breakpoint-up(sm) {
    flex: 0 0 40%;
    order: 2;
  }
}

.NextArticle-content {
  @include media-breakpoint-up(sm) {
    display: flex;
    flex-direction: column;
    flex: 0 0 60%;
    padding-right: 3rem;
  }
}

.NextArticle-figure {
  position: relative;
  padding-bottom: 100%;
}

.NextArticle-category {
  color: $color-accent;
}

.NextArticle-title {
  margin: 0;
}


.HpMagazine {
  background-color: $color-background;
  @include prop-res("padding-top", $section-spacer);
  @include prop-res("padding-bottom", $section-spacer);
  overflow: hidden;
}

.HpMagazine-header {
  margin-bottom: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include media-breakpoint-down(xs) {
    margin-bottom: 1.5rem;
    padding-top: 1.5rem;
    border-top: 1px solid rgba(0,0,0,.2);
  }
  h2 {
    margin: 0;
  }
  @include media-breakpoint-up(sm) {
    margin-bottom: 2rem;
  }
}


.HpMagazine-cta {
  color: color("turtledove") !important;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  font-family: $font-family-sans-serif;
  font-weight: 700;
  font-size: 0.8rem;
  text-transform: uppercase;
  .Arrow {
    margin-left: 1rem;
  }
  .HpMagazine-header & {
    @include media-breakpoint-down(xs) {
      display: none;
    }
  }
}

.HpMagazine-container {
  @include make-container();
}

.HpMagazine-list {
  overflow: visible;
  margin-bottom: 1.5rem;
  @include make-row();
  @include media-breakpoint-down(sm) {
    margin-left: 0;
    margin-right: 0;
  }
  @include media-breakpoint-up(md) {
  }
  .swiper-slide {
    @include grid-spacer("m", "r");
    height: auto;
    flex: 0 0 85%;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      @include prop-res("right", $grid-gutter-widths, -1);
      @include prop-res("width", $grid-gutter-widths, 0.5);
      border-left: 1px solid rgba(#000, .2);
      @include media-breakpoint-down(xs) {
        display: none;
      }
    }
    &:last-child {
      margin-right: 0;
      &:after {
        display: none;
      }
    }
    @include media-breakpoint-up(sm) {
      flex: 0 0 43%;
    }
    @include media-breakpoint-up(md) {
      @include make-col-ready();
      @include make-col(3);
      margin-right: 0;
      &:after {
        right: 0;
        border-left: none;
        border-right: 1px solid rgba(#000, .2);
      }
    }
  }
  .swiper-wrapper {
    @include media-breakpoint-up(md) {
      transform: none !important;
    }
  }
}

.HpMagazine-footer {
  display: flex;
  justify-content: flex-end;
  @include media-breakpoint-up(sm) {
    display: none;
  }
}

/*
.HpMagazine-item {
  @include make-col-ready();
  @include make-col(3);
  position: relative;
  &:before {
    content: '';
    position: absolute;
    display: block;
    right: 0;
    top: 0;
    bottom: 0;
    border-left: 1px solid #ccc;
  }
  &:last-child:before {
    display: none;
  }
  > * {
    margin-bottom: 0;
  }
}
*/


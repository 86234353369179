.Temp {
  .PageHeader {
    padding-top: 0;
    h1 {
      margin-top: 1em;
      margin-bottom: 1em;
    }
    h6 {
      color: color("turtledove");
    }
  }

  .PageHeader-container {
    @include prop-res("margin-top", $section-spacer, .75);
  }

  .PageHeader-cols {
    @include make-col-ready();
    @include media-breakpoint-up(md) {
      @include make-col(8);
      @include make-col-offset(2);
    }
  }

  .Highlight {
    @include media-breakpoint-down(xs) {
      br {
        // display: none;
      }
    }

    .Highlight-title {
      @include media-breakpoint-up(sm) {
        @include make-col(12);
        @include make-col-offset(0);
      }
      @include media-breakpoint-up(md) {
        @include make-col(8);
        @include make-col-offset(2);
      }
    }
  }
}

.Temp-logo {
  @include make-col-ready();
  @include media-breakpoint-up(md) {
    @include make-col(8);
    @include make-col-offset(2);
  }
  svg {
    height: 4rem;
    width: auto;
    path {
      fill: currentColor;
    }
  }
}

.Temp-gallery {
  @include prop-res("padding-bottom", $section-spacer);
  h6 {
    display: flex;
    justify-content: center;
    a {
      margin: 0 .5rem;
    }
  }
}

.Temp-gallery-row {
  @include prop-res("margin-right", $grid-container-paddings, -0.25);
  @include prop-res("margin-left", $grid-container-paddings, -0.25);
  @include media-breakpoint-up(sm) {
  }
}

.Temp-gallery-col {
  text-align: center;
  margin-bottom: 2.5rem;
  @include prop-res("padding-right", $grid-container-paddings, 0.25);
  @include prop-res("padding-left", $grid-container-paddings, 0.25);
  @include media-breakpoint-up(md) {
    @include make-col(4);
  }
}

.Temp-gallery-cover {
  margin: 0 auto 1.5rem;
  // max-width: 300px;
}

.Temp-gallery-image {
  display: block;
  padding-bottom: 100%;
  background-size: cover;
  background-position: center;
}

.Temp-info {
  @include prop-res("padding-bottom", $section-spacer);
  h6 {
    color: color("turtledove");
  }
}

.Temp-info-col {
  margin-bottom: 2rem;
}

.Temp-footer {
  text-align: center;
  background-color: $color-background-footer;
  @include prop-res("padding-top", $section-spacer, .5);
  @include prop-res("padding-bottom", $section-spacer, .5);
  ul {
    list-style: none;
    position: relative;
    margin: 0 0 2rem;
    padding: 0;
  li {
    margin: 0 .5rem;
  }
  a {
    text-decoration: none;
    color: $color-accent;
    }
  }
  p {
    margin: 0;
  }
}

.Temp-link {
  text-decoration: none !important;
  background-image: linear-gradient(rgba(color("gray-15"),.15), rgba(color("gray-15"),.15));
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 100% 2px;
  &:hover {
    background-image: linear-gradient(rgba(color("gray-15"),1), rgba(color("gray-15"),1));
  }
}



.c-scrollbar {
  z-index: $zindex-scrollbar;
  opacity: 1 !important;
  // mix-blend-mode: difference;
}

.c-scrollbar_thumb {
  // mix-blend-mode: difference;
  opacity: 1;
  width: 6px;
  background-color: #ccc;
  border-radius: 0;
  // margin: 0;
  // width: 100%;
}

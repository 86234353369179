.Contact {
  color: $color-text-inverted;
  background-color: $color-background-dark;

  @include media-breakpoint-up(md) {
    margin-left: 50%;
    min-height: 100vh;
  }

  a {
    color: inherit;
  }

  h2 {
    opacity: .3;
    &:not(:first-child) {
      margin-top: 5rem;
    }
  }

  .Button {
    margin-top: 2rem;
  }
}

.Contact-container {
  padding: 5rem 0;
  @include make-container();
  max-width: 800px;
}

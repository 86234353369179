.Intro {
  position: fixed;
  z-index: $zindex-intro;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .Logo-mark {
    position: static;
    height: auto;
  }

  .Logo-type {
    position: static;
    margin: 0;
    transform: none;
    left: 0;
    top: 0;
  }
}

.Intro-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 12rem;
}

.Intro-logomark {
  width: 45%;
  margin: 0 auto 10%;
  opacity: 0;
}

.Intro-logotype {
  opacity: 0;
}

.Intro-screen {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform-origin: 0 0;
}

.Intro-screen--mask {
  background: #fff;
}

.Intro-screen--bg {
  background-color: rgba(#000, .1);
}

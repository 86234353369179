.TextCols {
  // @include prop-res("margin-top", $section-spacer);
  @include prop-res("padding-bottom", $section-spacer, 0.5);
  background-color: $color-background;

  &--background {
  }
}

.TextCols--background .TextCols-container {
  @include prop-res("padding-top", $section-spacer);
  @include prop-res("padding-bottom", $section-spacer, 0.5);
  background-color: color("sand");

}

.TextCols-wrapperCol,
.TextCols-col {
  @include make-col-ready();
  @include make-col(12);
}

.TextCols-wrapperCol {
  @include media-breakpoint-up(sm) {
    @include make-col(8);
    @include make-col-offset(2);
  }
  @include media-breakpoint-up(md) {
    @include make-col(6);
  }
  .TextCols--2cols & {
    @include media-breakpoint-up(sm) {
      @include make-col(12);
      @include make-col-offset(0);
    }
    @include media-breakpoint-up(md) {
      @include make-col(10);
      @include make-col-offset(1);
    }
    @include media-breakpoint-up(lg) {
      @include make-col(8);
      @include make-col-offset(2);
    }
  }
  .TextCols--3cols & {
    @include media-breakpoint-up(sm) {
      @include make-col(12);
      @include make-col-offset(0);
    }
    @include media-breakpoint-up(lg) {
      @include make-col(10);
      @include make-col-offset(1);
    }
  }
}

.TextCols-col {
  @include prop-res("margin-bottom", $section-spacer, 0.5);
  > *:last-child {
    margin-bottom: 0;
  }
  .TextCols--2cols & {
    @include media-breakpoint-up(sm) {
      @include make-col(6);

      &:nth-child(even) {
        --index: 1;
      }
    }
  }
  .TextCols--3cols & {
    @include media-breakpoint-up(sm) {
      @include make-col(4);

      &:nth-child(3n+2) {
        --index: 1;
      }

      &:nth-child(3n+3) {
        --index: 2;
      }
    }
  }
}

.MenuList {
  transform: translateZ(0);
  background-color: $color-background-dark;
  @include media-breakpoint-up(md) {
    margin-left: 50%;
  }
  @include media-breakpoint-down(xs) {
    padding: 2rem 0;
  }
}

.MenuList-item {
  @include media-breakpoint-up(sm) {
    padding: 6rem 0 0;
    &:last-child {
      padding-bottom: 6rem;
    }
  }
  @include media-breakpoint-up(md) {
    padding: 5rem 0;
    min-height: calc(100vh - 5rem);
    &:last-child {
      min-height: 100vh;
    }
    // opacity: 0;
    // transition: opacity 2s;
    // &.is-inview {
    //   opacity: 1;
    // }
  }
}

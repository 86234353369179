.Prose {
  > *:first-child,
  // for ckeditor
  .cke_widget_wrapper:first-child > *:first-child,
  span:first-child + .cke_widget_wrapper > *:first-child {
    margin-top: 0 !important;
  }

  > *:last-child {
    margin-bottom: 0 !important;
  }

  table {
    width: 100% !important;
    border: none;
    border-spacing: 0;
    border-collapse: collapse;

    h6 {
      border-top: 1px solid currentColor;
      padding-top: 1rem;
      margin: 1rem 0 0;
    }
  }

  td {
    border: 0;
    padding: 0 0 0.5rem;
    vertical-align: top;

    &:last-child:not(:first-child) {
      text-align: right;
    }
  }
}

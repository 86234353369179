.Menu {
  color: $color-text-inverted;
  background-color: color("gray-11");
  @include media-breakpoint-down(xs) {
    // border-bottom: 1px solid rgba($color-text-inverted, .2);
    // padding: 1rem 0;
  }
}

.Menu-container {
  @include make-container();
}

.Menu-title {
  opacity: .3;
  margin-bottom: 2rem;

  svg {
    opacity: .3;
    fill: none;
    stroke: currentColor;
    stroke-width: 1.5px;
    width: 16px;
    height: 16px;

    @include media-breakpoint-up(sm) {
      display: none;
    }
  }
  &.is-open svg {
    transform: rotate(180deg);
  }
  // added by js to make mobile tap visibile
  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: inherit;
    text-decoration: none;
    pointer-events: none;
  }
  @include media-breakpoint-down(xs) {
    a {
      pointer-events: auto;
    }
    cursor: pointer;
    -webkit-user-select: none;
    opacity: 1;
    margin: 0;
    padding: 1.5rem 0;
    border-bottom: 1px solid rgba($color-text-inverted, .2);
    .MenuList-item:last-child & {
      border: none;
    }
  }
}

.Menu-content {
  @include media-breakpoint-down(xs) {
    > *:last-child {
      padding-bottom: 4rem;
    }
  }
  @include media-breakpoint-up(sm) {
    display: block !important;
    height: auto !important;
  }
}

.Menu-item {
  display: flex;
  border-top: 1px solid currentColor;
  padding: .5rem 0 2rem;
  @include media-breakpoint-down(xs) {
    padding: 1rem 0;
    border-top: 1px solid rgba($color-text-inverted, .2);
    &:first-child {
      border: none;
    }
    &:last-child {
      border-bottom: 1px solid rgba($color-text-inverted, .2);
    }
  }
}

.Menu-item-title {
  text-transform: none;
  margin-bottom: 0;
  line-height: $line-height-base;
}

.Menu-item-price {
  margin-bottom: 0;
  flex: 1 0 25%;
  white-space: nowrap;
  text-align: right;
  margin-left: auto;
  padding-left: 2rem;
  line-height: $line-height-base;
  &:before {
    content: '€ ';
  }
}

.Menu-item-content {
  > * {
    margin-bottom: 0;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      padding-left: 0.7em;
      position: relative;
      &:before {
        content: '-';
        position: absolute;
        left: 0;
      }
    }
  }
}

.Menu-item-note,
.Menu-note {
  font-family: $font-family-stencil;
  > * {
    margin-bottom: 0;
  }
}

.Menu-item-note {
  margin-top: 1rem;
}

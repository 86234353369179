.SplitCover {
  position: relative;
  background-color: #eee;
  height: 100vw;
  @include media-breakpoint-up(md) {
    margin-left: 50%;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
}

.SplitCover-figure,
.SplitCover-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.SplitCover-image {
  background-size: cover;
  background-position: center;

}

.SplitCover-cta {
  position: absolute;
  bottom: 1rem;
  left: 0;
  right: 0;
  text-align: center;
  color: color("turtledove");
  opacity: 1;
  transition: opacity 0.8s;
  @extend .h5;
  @include media-breakpoint-down(sm) {
    display: none;
  }

  &.is-inview {
    opacity: 0;
  }
}


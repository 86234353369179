.FullImage {
  background-color: #fff;
  @include prop-res("padding-bottom", $section-spacer);
}

.FullImage-container {
  @include make-container();
}

.FullImage-content {
  @include prop-res("margin-left", $grid-container-paddings, -1);
  @include prop-res("margin-right", (xs: map_get($grid-container-paddings, xs), sm: 0), -1);
}

.FullImage-wrapImage {
  overflow: hidden;
  position: relative;
  padding-bottom: 50%;

  @include media-breakpoint-down(xs) {
    padding-bottom: 100%;
  }
}

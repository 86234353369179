h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: $headings-margin-bottom;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  // color: $headings-color;
}

h1, .h1 {
  // 70 px -> 35px
  // split pages title
  // page header title
  // home page sections
  -webkit-text-stroke: $text-stroke-width;
  .desktop & {
  }
  line-height: 1.15;
  @include ms-respond(font-size, 36);
  @include media-breakpoint-up(sm) {
    font-weight: 200;
  }
}

h2, .h2 {
  // 52px -> 35px
  // article title (h1 probably)
  // subsection title on static pages
  // carte section titles
  .desktop & {
    -webkit-text-stroke: $text-stroke-width;
  }
  @include ms-respond(font-size, 26);
  //
  //
  // @include mike(30rem, 150rem, 1rem, 4rem);
}

h3, .h3 {
  // 46 px
  // page header subtitles
  // highlight titles (quotes?)
  // menu items
  .desktop & {
    -webkit-text-stroke: $text-stroke-width;
  }
  @include ms-respond(font-size, 23);
  // @include ms-responsive(23);
}

h4, .h4 {
  // 26px
  // news list titles
  .desktop & {
    -webkit-text-stroke: $text-stroke-width;
  }
  @include ms-respond(font-size, 11);
  // @include ms-responsive(11);
}

h5, .h5 {
  // 16px
  // carte sub sections
  // context titles
  font-family: $font-family-sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.9rem;
  @include media-breakpoint-down(sm) {
    font-size: 1rem;
  }
}

h6, .h6 {
  // 14px
  // service links
  // meta data text (date)
  font-family: $font-family-sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.8rem;
  @include media-breakpoint-down(sm) {
    font-size: .9rem;
  }
}

small,
.small {
  // @include small();
}

.serif {
  // font-family: $font-family-serif;
}

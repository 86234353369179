.Nav {
  /* ie 11 fix */
  display: flex;
  flex-direction: column;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  p {
    margin: 0;
  }
  a {
    text-decoration: none;
  }
}

.Nav-container {
  @include make-container();
  @include poly-fluid-sizing('padding-top', $header-height);
  padding-bottom: 4rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .SiteHeader & {
    // min-height: auto;
  }
  .row {
    &:first-child {
    }
    > * {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
    &:first-child > * {
      padding-top: 2rem;
    }
    &:last-child > * {
      // padding-bottom: 2rem;
    }
    @include media-breakpoint-up(sm) {
      &:first-child {
        margin-top: auto;
        margin-bottom: auto;
      }
    }
  }
}


.Nav-main,
.Nav-cta,
.Nav-social,
.Nav-colophon {
  @include make-col(12);
}

.Nav-main,
.Nav-cta {
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
}

.Nav-main {
  @include ms-respond(font-size, 25);
  @include media-breakpoint-up(sm) {
    @include make-col(4);
  }
  @include media-breakpoint-up(md) {
    @include make-col-offset(1);
  }
  a {
    @include underline(transparent, $color-accent-fade);
  }
  li.is-active {
    position: relative;
    &:before {
      content: '•';
      position: absolute;
      top: 50%;
      transform: translateY(-50%) translateZ(0);
      left: -0.7em;
      font-size: 0.58em;
    }
  }
  // li {
  //   @include media-breakpoint-down(xs) {
  //     opacity: 1 !important;
  //     transform: none !important; // disable menu reveal effect on mobile
  //   }
  // }
}

.Nav-cta {
  @include ms-respond(font-size, 25);
  color: color("turtledove");
  a {
    color: $color-text;
    @include underline();
  }
  @include media-breakpoint-up(sm) {
    @include make-col(8);
  }
  @include media-breakpoint-up(md) {
    @include make-col(6);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(5);
  }
  .SiteHeader & {
    @include media-breakpoint-down(xs) {
      display: none;
    }
  }
}

.Nav-main {
  @include media-breakpoint-down(xs) {
    display: none;
    .SiteHeader & {
      display: block;
    }
  }
}

.Nav-social {
  font-family: $font-family-sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.8rem;
  a {
    color: color("turtledove") !important; // overwrite .SiteHeader a
  }
  @include media-breakpoint-down(xs) {
    display: none;
    .SiteHeader & {
      display: block;
    }
  }
  @include media-breakpoint-up(sm) {
    @include make-col(4);
  }
  @include media-breakpoint-up(md) {
    @include make-col-offset(1);
  }
}

.Nav-colophon {
  transform: translateZ(0);
  @include small();
  @include media-breakpoint-down(xs) {
    line-height: 1.7;
  }
  @include media-breakpoint-up(sm) {
    @include make-col(8);
  }
  @include media-breakpoint-up(md) {
    @include make-col(6);
  }
}

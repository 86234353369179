.History {
  @include prop-res("padding-bottom", $grid-container-paddings);
  background-color: $color-background;
  color: $color-text-inverted;
  overflow: hidden;
  @include media-breakpoint-down(xs) {
    padding-bottom: 0;
  }
}

.History-title {
  position: relative;
  @include media-breakpoint-up(sm) {
    display: none;
  }
}

.History-container {
  position: relative;
  @include prop-res("padding-top", $section-spacer);
  @include prop-res("padding-bottom", $section-spacer);
}

.History-bg {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  bottom: 0;
  background-color: $color-background-dark;
  @include prop-res("right", $grid-container-paddings);
  @include media-breakpoint-down(xs) {
    right: 0;
  }
}

.History-gallery {
  @include make-col(11);
  @include media-breakpoint-up(sm) {
    @include make-col(7);
    order: 2;
  }
  .swiper {
    width: 100%;
    overflow: visible;
  }
  .swiper-slide {
    background-color: $color-background-dark;
    &:not(:last-child) {
      @include prop-res("margin-right", $grid-gutter-widths);
    }
  }
  .swiper-slide-next {
    cursor: pointer;
  }
}

.History-image {
  height: 0;
  padding-bottom: 2/3 * 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0;
  transition: opacity .6s;
  &.lazyloaded {
    opacity: 0.5;
  }
  .swiper-slide-active &.lazyloaded {
    opacity: 1;
  }
}

.History-captions {
  margin-top: 1rem;
  @include make-col(12);
  @include media-breakpoint-up(sm) {
    margin-top: 0;
    @include make-col(4);
  }
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  &:before {
    @include media-breakpoint-up(sm) {
      content: '';
    }
    position: absolute;
    background-color: $color-background-dark;
    top: -2px;
    bottom: -2px;
    $left-widths : (
      sm: -1.5rem,
      md: -1.5rem
    );
    $right-widths : (
      sm: -1rem,
      md: -1.5rem
    );
    @include prop-res("left", $left-widths);
    @include prop-res("right", $right-widths);
  }
  .swiper {
    width: 100%;
    margin-bottom: auto;
  }
  .swiper-wrapper {
    transition-duration: 0ms !important;
  }
  .swiper-slide p {
    @include media-breakpoint-up(md) {
      padding-right: 32px;
    }
  }
  .swiper-fade {
    .swiper-slide {
       p {
        transition: opacity .8s, transform .8s;
        transition-timing-function: cubic-bezier(easeOutQuart);
        opacity: 0;
        transform: translateY(20px);
      }
      &.swiper-slide-active p {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}

.History-captions-title {
  position: relative;
  margin-top: auto;
  @include media-breakpoint-down(xs) {
    display: none;

  }
}

.History-nav {
  position: relative;
  display: flex;
  .tablet &,
  .mobile & {
    display: none;
  }
}

.History-button {
  color: inherit;
  user-select: none;
  cursor: pointer;
  transition: opacity .6s;
  border: none;
  padding: 0;
  appearance: none;
  background: none;
  outline: none !important;
  &--prev {
    position: absolute;
    opacity: 0;
    pointer-events: none;
    svg {
      transform: scaleX(-1);
    }
  }
}

.History.is-ended .History-button--next {
  opacity: 0;
  pointer-events: none;
}

.History.is-ended .History-button--prev {
  opacity: 1;
  pointer-events: auto;
}



.SiteHeader {
  position: fixed;
  z-index: $zindex-header;
  top: 0;
  width: 100%;
  pointer-events: none;
  // @include poly-fluid-sizing('padding-top', $header-padding);
  @include prop-res("padding-top", $grid-container-paddings, 0.5);

  a {
    color: $color-text !important;
  }
}

.SiteHeader-container {
  display: flex;
  align-items: center;
  position: relative;
  z-index: $zindex-header-container;
}

.SiteHeader-logo {
  display: block;
  margin-right: .65rem;
  pointer-events: auto;
  position: relative;
  transition: transform .6s cubic-bezier("easeInOutQuart");
  .Logo-type-wrapper {
    transform: translateY(24px);
    transition: transform .6s cubic-bezier("easeInOutQuart");
  }
  .Logo-type {
    transition: transform .1s cubic-bezier("easeOutQuart") .2s, opacity .2s;
    transform: translateY(-50%) translateX(1rem) scale(1.8);
    opacity: 0;
  }
  html.footer-enter:not(.is-transitioning-enter) & {
    @include media-breakpoint-up(sm) {
      transform: translate(0, 24px);
      .Logo-type-wrapper {
        transform: translateY(0);
      }
      .Logo-type {
        transition: transform .8s cubic-bezier("easeOutQuart"), opacity .3s;
        transition-delay: .4s;
        transform: translateY(-50%) translateX(0) scale(1.8);
        opacity: 1;
      }
    }
  }
}

.SiteHeader-menuToggle {
  @include poly-fluid-sizing('width', $logo-size);
  @include poly-fluid-sizing('height', $logo-size);
  display: block;
  position: relative;
  pointer-events: auto;
  transition: opacity .6s;
  html.footer-enter:not(.is-transitioning-enter) & {
    @include media-breakpoint-up(sm) {
      opacity: 0;
      pointer-events: none;
    }
  }
  &[aria-pressed="true"] line {
    &:nth-child(2) {
      transform: translateY(-9px) scaleX(0);
    }
    &:nth-child(3) {
      transform: translateY(9px) scaleX(0);
    }
  }
}

.SiteHeader-menuCircle {
  position: absolute;
  overflow: visible;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  fill: rgba(#fff, 0.95);
  stroke: currentColor;
  stroke-width: 2px;
  line {
    stroke-width: 3px;
    transition-property: transform;
    transform-origin: 50% 50%;
    &:nth-child(2) {
      transition-duration: .4s;
      transition-timing-function: cubic-bezier("easeOutQuart");
      transform-origin: 25%;
      transform: translateY(-9px);
    }
    &:nth-child(3) {
      transition-duration: .45s;
      transition-timing-function: cubic-bezier("easeOutSine");
      transform-origin: 75%;
      transform: translateY(9px);
    }
  }
}

.desktop .SiteHeader-menuToggle:not([aria-pressed="true"]):hover line {
  &:nth-child(2) {
    transform: translateY(-9px) scaleX(0.5);
  }
  &:nth-child(3) {
    transform: translateY(9px) scaleX(0.7);
  }
  &:nth-child(4) {
    transform: scaleX(1);
  }
}

.SiteHeader-nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $zindex-header-nav;
  display: none;
  .show-menu & {
    pointer-events: auto;
    display: block;
  }
}

.SiteHeader-nav-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  background-color: rgba(0,0,0,.5);
}

.SiteHeader-nav-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: scaleY(0);
  transform-origin: 0 0;
  background-color: #fff;
  will-change: transform;
}

.SiteHeader-nav-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

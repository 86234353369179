.Highlight {
  // @include prop-res("margin-top", $section-spacer);
  @include prop-res("padding-bottom", $section-spacer);
  background-color: $color-background;
}

.Highlight-title {
  margin-bottom: 0;
  color: color("turtledove");
  @include make-col-ready();
  @include media-breakpoint-up(sm) {
    @include make-col(8);
    @include make-col-offset(2);
  }
  @include media-breakpoint-up(md) {
    @include make-col(6);
    .Highlight--right & {
      @include make-col-offset(4);
    }
  }
}

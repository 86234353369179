.Mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-mask;
  pointer-events: none;

  > * {
    position: absolute;
    top: 0;
    bottom: 0;
  }
}

.Mask-bg {
  left: 0;
  right: 0;
  background-color: rgba(#000, .1);
  opacity: 0;
}

.Mask-split {
  width: 50%;
  background-color: #fff;
  will-change: transform;
}

.Mask-split--one {
  left: 0;
  transform: translateY(-100%);

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.Mask-split--two {
  right: 0;
  transform: translateY(100%);

  @include media-breakpoint-down(sm) {
    display: none;
  }
}



// require attr value with the same text
.Button {
  appearance: none;
  text-decoration: none !important;
  display: inline-block;
  border: none;
  color: $color-text-inverted !important;
  background-color: transparent;
  font-family: $font-family-stencil;
  font-weight: 300;
  padding: .25em 2em;
  background-color: $input-focus-bg;
  @include ms-respond(font-size, 12);
  position: relative;
}

.Button[value],
.Button[title] {
  &:after {
    position: absolute;
    pointer-events: none;
    z-index: 1;
    padding: .25em 2em;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    color: $color-text;
    clip-path: inset(100% 0 0 0);
    transition: clip-path .5s cubic-bezier("easeOutQuart")
  }
  .desktop &:hover:after {
    clip-path: inset(0 0 0 0);
  }
}

.Button[value]:after {
  content: attr(value)
}

.Button[title]:after {
  content: attr(title)
}

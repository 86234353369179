.Article {
  position: relative;
  z-index: 1;
  background-color: color("sand");
  @include media-breakpoint-up(md) {
    margin-left: 50%;
    min-height: 100vh;
  }
}

.Article-container {
  @include make-container();
  padding-top: 3rem;
  padding-bottom: 3rem;
  @include media-breakpoint-up(md) {
    min-height: 100vh;
    html.has-scroll-smooth & {
      padding-top: 7rem;
      padding-bottom: 7rem;
    }
  }
  @include media-breakpoint-up(lg) {
    padding-left: 8vw;
    padding-right: 8vw;
  }

}

.Article-category {
  a {
    text-decoration: none;
    color: $color-accent;

    .hide-magazione-nav & {
      pointer-events: none;
    }
  }
  @include media-breakpoint-up(md) {
    text-align: center;
  }
}

.Article-title {
  margin-bottom: 3rem;
  @include media-breakpoint-up(md) {
    text-align: center;
  }
}

.Article-gallery {
  margin: 3rem 0;
  &:last-child {
    margin-bottom: 0;
  }
}

.Article-gallery-item {
  &:not(:last-child) {
    margin-bottom: 3rem;
  }
}

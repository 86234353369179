.Arrow {
  fill: none;
  stroke: currentColor;
  stroke-width: 1.8px;
  transform: translateZ(0);
  @include poly-fluid-sizing('width', $arrow-size);
  @include poly-fluid-sizing('height', $arrow-size);
  cursor: pointer;
  polyline {
    display: none;
  }
  .desktop & g {
    opacity: .6;
    transition: opacity .6s;
  }
  polyline,
  path {
    transform: translateX(-2px);
    transition: transform .6s;
  }
  line {
    transform: scaleX(0.8);
    transform-origin: center;
    transition: transform .6s;
  }
  .desktop a:hover &,
  .desktop button:hover &{
    g {
      opacity: 1;
    }
    polyline,
    path {
      transform: translateX(2px);
    }
    line {
      transform: scaleX(1.1);
    }
  }

}

.Arrow-bg {
  .desktop & {
    opacity: .6;
    transition: opacity .8s;
    transition-delay: .2s;
  }
  .desktop a:hover &,
  .desktop button:hover & {
    opacity: 0;
    transition-delay: 0s;
  }
}

.Arrow-progress {
  transform: rotate(0);
  transform-origin: 50% 50%;
  stroke-dashoffset: 201;
  transition: transform .6s, stroke-dashoffset .8s cubic-bezier("easeInOutQuart");
  stroke-dasharray: 201;
  .desktop a:hover &,
  .desktop button:hover & {
    stroke-dashoffset: 0;
    transform: rotate(180deg);
  }
}

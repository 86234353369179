
html {
  @include poly-fluid-sizing('font-size', $font-size-root);
  -webkit-tap-highlight-color: rgba(#fff, 0.2);
  @include font-smoothing("antialiased");

  &.has-scroll-smooth {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  // @include responsive-font(vw(16,768), 16px);
  // @each $breakpoint in map-keys($font-size-root) {
  //   @include media-breakpoint-up($breakpoint) {
  //     font-size: map-get($font-size-root, $breakpoint);
  //   }
  // }
}

.icon-stroke {
  fill: none;
  stroke: currentColor;
  stroke-width: 0.05rem;
}


html.frontend {
  body {
    overscroll-behavior-y: none; // prevent overscroll on body
  }
  &:not(.ready) body {
    overflow: hidden;

    > *:not(.Intro) {
      opacity: 0.01;
    }
  }
}

html.is-transitioning {
  * {
    cursor: progress !important;
  }
}

body.cke_editable {
  margin: 1rem;
}

// body {
//   &:not(.cke_editable):not(.cke_ltr) { // fix for ckeditor
//     overscroll-behavior-y: none; // prevent overscroll on body
//     html:not(.ready) & {
//       opacity: 0;
//     }
//   }
// }

figure {
  margin-bottom: 0;
}

label {
  color: inherit;
  font-family: $input-font-family;
  font-weight: 600;
  font-size: calc(1rem - 2px);
}

form {
  color: $color-text-inverted;
  background-color: $color-background-dark;
}

.js-focus-visible :focus:not(.focus-visible),
.js-focus-visible button:focus:not(.focus-visible) {
  outline: none;
}

.focus-visible:not(input):not(textarea) {
  outline: 4px solid #ccc !important;
  outline-offset: 4px !important;
}

input,
textarea,
select {
  &:-webkit-autofill,
  .desktop &:-webkit-autofill:hover {
    -webkit-box-shadow: 0 0 0 1000px $input-bg inset !important;
    -webkit-text-fill-color: #fff !important;
    transition: webkit-box-shadow;
  }
  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px $input-focus-bg inset !important;
  }
}

[data-scroll-section] {
  &::after {
    content: '';
    position: absolute;
    top: 0;
    display: block;
    will-change: transform;
    width: 100%;
    height: 1px;
    pointer-events: none;
  }
}

[data-scroll-speed] {
  will-change: transform;
}

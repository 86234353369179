.PageHeader {
  @include poly-fluid-sizing('padding-top', $header-height);
  @include prop-res("padding-bottom", $section-spacer);
  background-color: $color-background;
}

.PageHeader-container {
  @include prop-res("margin-top", $section-spacer);
}

.PageHeader-title,
.PageHeader-subtitle {
  margin-bottom: 0;
  @include make-col-ready();
  @include media-breakpoint-up(md) {
    @include make-col(8);
    @include make-col-offset(2);
  }
}

.PageHeader-subtitle {
  color: color("turtledove");
}

.dropdown {
  // font-family: $input-font-family;
}

.dropdown-item {
  transition: background .3s;
  &:hover {
    transition-duration: .2s;
  }
}

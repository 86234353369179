.Grid {
  @include prop-res("padding-bottom", $section-spacer);
  background-color: $color-background;
}

.Grid-row {
  display: flex;
  flex-direction: column;
  @include media-breakpoint-up(sm) {
    flex-direction: row;
  }
}

.Grid-item {
  flex: 0 0 100%;
  @include media-breakpoint-down(xs) {
    padding-left: map-get($grid-container-paddings, xs);
    padding-right: map-get($grid-container-paddings, xs);
  }
  @include media-breakpoint-up(sm) {
    flex: 0 0 40%
  }
  @include media-breakpoint-up(md) {
    flex: 0 0 33.333%
  }
}

.Grid-item--image {
  display: flex;
  .Grid-content {
    position: relative;
    width: 100%;
    &:before {
      content: '';
      display: block;
      height: 0;
      padding-bottom: 2.6/2 * 100%;
    }
  }
  @include media-breakpoint-down(xs) {
    order: -1;
  }
  @include media-breakpoint-up(sm) {
    .Grid:not(.Grid--invertOrder) .Grid-row:nth-child(odd) &,
    .Grid--invertOrder .Grid-row:nth-child(even) & {
      order: -1;
    }
    .Grid-row:nth-child(odd) & {
      flex: 0 0 60%;
      .Grid-content:before {
        padding-bottom: 2/3 * 100%;
      }
    }
  }
  @include media-breakpoint-up(md) {
    .Grid-row:nth-child(odd) & {
      flex: 0 0 66.666%;
    }
  }
}

.Grid-item--text {
  align-self: center;
  .Grid-content {
    $spacer: map-merge($grid-container-paddings, (xs: 0));
    @include grid-spacer("p", "y", 1, $grid-container-paddings);
    @include grid-spacer("p", "x", 1, $spacer);
    @include media-breakpoint-down(xs) {
      .Grid-row:last-child & {
        padding-bottom: 0;
      }

    }
  }
  @include media-breakpoint-up(sm) {
    .Grid-row:nth-child(even) & {
      flex: 0 0 60%;
      .Prose {
        @include prop-res("column-gap", $grid-gutter-widths);
        @include media-breakpoint-up(md) {
          p {
            columns: 2;
          }
        }
      }
    }
  }
  @include media-breakpoint-up(md) {
    .Grid-row:nth-child(even) & {
      flex: 0 0 66.666%;
    }
  }
}

.Grid-image {
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

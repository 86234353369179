@mixin resolve-breakpoints($map) {
  $resolve : ();
  @each $breakpoint in map-keys($map) {

  }
}

@mixin small() {
  @include font-size-small();
}

@mixin font-size-small() {
  line-height: $line-height-sm;
  font-size: calc(1rem - 1px);
}

// output a css property with a responsive map of values
@mixin prop-res($prop: "margin-bottom", $values: (xs: 1rem), $factor: 1) {
  @each $breakpoint in map-keys($values) {
    @include media-breakpoint-up($breakpoint) {
      #{$prop}: map-get($values, $breakpoint) * $factor;
    }
  }
}

@mixin if-smooth-scroll() {
  .has-scroll-smooth  & {
    @content;
  }
}

@mixin underline($color: $color-accent-fade, $hover-color: $color-text) {
  background-image:  linear-gradient($hover-color, $hover-color), linear-gradient($color, $color);
  background-size: 0% 2px, 100% 2px;
  background-repeat: no-repeat;
  background-position: 0 88%, 0 88%;
  transition: background-size .4s cubic-bezier("easeInOutQuart");
  .desktop &:hover {
    background-size: 100% 2px, 100% 2px;
  }
}

@function svg($svg-name, $svg-style: "", $encode: true) {
  $svg : map_get($svg, $svg_name);
  $svg : str-replace($svg, "{$svg-style}", $svg-style);
  @if($encode == true) {
    $svg : svg-encode($svg);
  }
  @return $svg;
}

@function getSVGStyle($color) {
  @return #{"fill: none; stroke: " + $color + "; stroke-width: 1.4;  stroke-linecap: round; stroke-linejoin: round;"};
}

// @mixin headerPadding() {
//   padding: map-get($grid-container-paddings, xs) 0;

//   @include media-breakpoint-up(sm) {
//     padding: map-get($grid-container-paddings, sm) 0;
//   }

//   @include media-breakpoint-up(lg) {
//     padding: 75px 0;
//   }
// }

// @mixin blockMargin() {
//   @include spacer-stack(5);
//   @include media-breakpoint-up(sm) {
//     @include spacer-stack(5);
//   }
//   @include media-breakpoint-up(lg) {
//     @include spacer-stack(6);
//   }
// }

// @mixin sectionMargin() {
//   $factor: (
//     xs: 4,
//     sm: 2,
//     md: 2,
//     lg: 2,
//     xl: 2,
//     xxl: 1.5
//   );
//   @include grid-spacer("m", "b", $factor, $grid-container-paddings);
// }

// @mixin sectionMarginReduced() {
//   $factor: (
//     xs: 2,
//     sm: 1,
//     md: 1,
//     lg: 1,
//     xl: 1,
//     xxl: 1
//   );
//   @include grid-spacer("m", "b", $factor, $grid-container-paddings);
// }

.Main {
  position: relative;
  z-index: $zindex-main;
  @include media-breakpoint-down(sm) {
    [data-scroll-speed] {
      transform: none !important;
    }
  }
}


.Main-container {
  > * {
    position: relative;
  }
  > *:not(.Main-footer) {
    z-index: 100;
  }
}

.Main-footer {
  html.has-scroll-init:not(.has-scroll-smooth) & {
    overflow: hidden;
    margin-bottom: 100vh;
    z-index: 1;
  }
}

.Main-footer-content {
  background: $color-background-footer;
  html.has-scroll-init:not(.has-scroll-smooth) & {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.NewsNav {
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin: 3rem auto;
  max-width: 20rem;
  padding: 0;
  font-family: $font-family-sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  // @include small();
  @extend .h6;
  letter-spacing: 0.05em;

  .hide-magazione-nav & {
    display: none;
  }
}

.NewsNav-item {
  // margin: 0 1em;
  // @include media-breakpoint-down(xs) {
  //   margin: 0 .5em;
  // }
}

.NewsNav-link {
  text-decoration: none !important;
  color: color("turtledove");
  position: relative;
  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    bottom: -0.3em;
    left: 0;
    height: 0.15em;
    background-color: currentColor;
    opacity: 0;
  }
  &.is-active {
    color: $color-text;
    &:before {
      opacity: 1;
    }
  }
}

.SplitHeader {
  background-color: color("white");
  @include prop-res("padding-top", $section-spacer);
  @include prop-res("padding-bottom", $section-spacer);
  @include media-breakpoint-up(md) {
    position: relative;
    z-index: 2;
    html:not(.has-scroll-smooth) & {
      position: sticky;
    }
    padding: 0;
    float: left;
    top: 0;
    left: 0;
    width: 50%;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
}

.SplitHeader--cover {
  padding: 0;
}

.SplitHeader-container {
  @include make-container();
  margin-top: auto;
  margin-bottom: auto;
  @include poly-fluid-sizing('padding-top', $header-height);
  @include media-breakpoint-up(md) {
    padding-top: 0;
  }
}

.SplitHeader-title {
  max-width: 5em;
  margin-bottom: 0;
}

.SplitHeader-nav {
  list-style: none;
  margin: 2rem 0 0;
  padding: 0;
  @extend .h6;
  @include media-breakpoint-down(xs) {
    display: none;
  }
}

.SplitHeader-navItem {
  margin-bottom: .5em;

  @for $i from 1 through 10 {
     &:nth-child(#{$i}) {
        --index: #{$i + 3};
     }
   }
}

.SplitHeader-navLink {
  text-decoration: none !important;
  color: $color-accent;
  display: inline-flex;
  align-items: center;
  transition: color .3s;

  &:before {
    content: '•';
    position: absolute;
    top: 50%;
    transition: opacity .3s;
    transform: translateY(-50%) translateZ(0);
    left: -1em;
    font-size: 1em;
    opacity: 0;
  }
  &.is-active {
    position: relative;
    // color: $color-text;
    &:before {
      opacity: 1;
    }
  }
}

.SplitHeader-figure {
  position: relative;
  height: 100vw;
  @include media-breakpoint-up(md) {
    height: auto;
    width: 100%;
    flex-grow: 1;
  }
}

.SplitHeader-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
}

.SplitHeader-articleHeader {
  position: absolute;
  width: 100%;
  left: 100%;
  background-color: color("sand");
  text-align: center;
  overflow: hidden;
  display: none;
  @include media-breakpoint-up(md) {
    html.has-scroll-smooth & {
      display: block;
    }
  }
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 3rem;
    right: 3rem;
    height: 2px;
    background-color: rgba(#000, .2);
  }
}

.SplitHeader-articleHeader-container {
  @include make-container();
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 5rem;
}

.SplitHeader-articleHeader-title {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transform: translateY(50%);
  opacity: 0;
  transition: opacity .6s, transform .6s;
  .show-title & {
    transform: translateY(0);
    opacity: 1;
  }
}

.SplitHeader-articleHeader-scoll {
  position: absolute;
  bottom: 0;
  left: 3rem;
  right: 3rem;
  height: 2px;
  background-color: $color-text;
  transform-origin: 0 0;
  transform: scaleX(0);
}

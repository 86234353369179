.Featured {
  background-color: $color-background;
  @include poly-fluid-sizing('padding-top', $logo-size);
  // @include prop-res("margin-bottom", $section-spacer);
  overflow: hidden;

  &:before {
    content: '';
    display: block;
    @include prop-res("height", $grid-container-paddings);
  }
  .Arrow {
    color: color("turtledove");
  }
  @include media-breakpoint-up(md) {
    padding-top: 0;
    &:before {
      display: none;
    }
  }
}

.Featured-item {
  display: flex;
  padding: 0 0 3rem 0;
  &:last-child {
    padding-bottom: 0;
  }
  @include media-breakpoint-up(md) {
    height: 100vh;
    padding: 0;
    &:nth-child(even) {
      flex-direction: row-reverse;
    }
  }
}

.Featured-cover {
  flex: 0 0 50%;
  position: relative;
  overflow: hidden;

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.Featured-figure {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


.Featured-content {
  flex: 0 1 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include prop-res("padding-left", $grid-container-paddings);
  @include prop-res("padding-right", $grid-container-paddings);

  // @include media-breakpoint-up(sm) {
  //   padding: 0 map_get($grid-container-paddings, sm);
  // }
  // @include media-breakpoint-down(sm) {
  //   align-items: flex-end;
  //   .Featured-item:nth-child(even) & {
  //     align-items: flex-start;
  //   }
  // }
}

.Featured-link {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-decoration: none !important;
  @include media-breakpoint-up(sm) {
    flex-direction: row-reverse;
    .Featured-item:nth-child(even) & {
      flex-direction: row;
    }
  }
  @include media-breakpoint-up(md) {
    flex-direction: column !important;
    justify-content: center;
    width: 60%;
    max-width: 40vh;
  }
}

.Featured-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  svg {
    margin-left: 2rem;
    flex-shrink: 0;
  }
  @include media-breakpoint-up(sm) {
    flex-direction: column;
    padding: 0 1.5rem;
    svg {
      margin: .5rem 0 0 0;
    }
  }
}

.Featured-title {
  --index: 2;

  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  -webkit-text-stroke: $text-stroke-width;
  // white-space: nowrap;
  @include ms-respond(font-size, 26);
}

.Featured-icon {
  --index: 3;
}

.Featured-postcard {
  position: relative;
  width: 100%;

  margin-bottom: 1rem;

  @include media-breakpoint-up(sm) {
    margin-bottom: 0;
    flex: 0 0 60%;
  }
  @include media-breakpoint-up(md) {
    width: 100%;
    flex: none;
    margin-bottom: 1rem;
  }

  &:before {
    display: block;
    content: '';
    padding-bottom: 120%;

    @include media-breakpoint-up(sm) {
      padding-bottom: 140%;
    }
  }
}

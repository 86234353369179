.Magazine {
  overflow: auto;
  background-color: color("sand");
  @include media-breakpoint-up(md) {
    margin-left: 50%;
    min-height: 100vh;
  }
}

.Magazine-container {
  @include make-container();
}

.Magazine-list {
  align-items: flex-start;
  @include make-row();
  margin-top: 3rem;
  margin-bottom: 6rem;
  @include media-breakpoint-up(sm) {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      height: 100%;
      left: 50%;
      width: 1px;
      background-color: rgba(#000, .15);
    }
  }

  .hide-magazione-nav & {
    margin-top: 4rem;
  }
}

.Magazine-item {
  @include make-col-ready();
  @include make-col(12);
  padding-bottom: 2rem;
  @include media-breakpoint-up(sm) {
    @include make-col(6);
    padding-bottom: 3rem;
    &:nth-child(odd) {
      padding-right: 2rem;
    }
    &:nth-child(even) {
      padding-left: 2rem;
    }
    &:last-child,
    &:nth-last-child(2):nth-child(odd) {
      padding-bottom: 0;
    }
  }
  @include media-breakpoint-only(md) {
    .ArticlePreview-title {
      font-size: 1.2rem;
      line-height: 1.15;
    }
    &:nth-child(odd) {
      padding-left: 1rem;
      padding-right: 1.5rem;
    }
    &:nth-child(even) {
      padding-left: 1.5rem;
      padding-right: 1rem;
    }
  }
  @include media-breakpoint-up(lg) {
    &:nth-child(odd) {
      padding-left: 1.5rem;
      padding-right: 2rem;
    }
    &:nth-child(even) {
      padding-left: 2rem;
      padding-right: 1.5rem;
    }
  }
}

.Figure {
  overflow: hidden;
  position: relative;
  background: $color-placeholder;
}

.Figure-image[data-bgset] {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: center/cover no-repeat;
}

.Figure-image {
  opacity: 0;
  transition: opacity 0.2s;

  &.--scale {
    transform: scale(1.02);
    transition: transform 1.2s cubic-bezier("easeOutQuart"), opacity 0.2s;
  }

  &.--mask {
    opacity: 1;
    transform: translateY(10%);
    transition: transform 1s cubic-bezier("easeOutQuart");
  }

  html.ready .is-inview &.lazyloaded {
    opacity: 1 !important;
    transform: none !important;
  }
}

.Figure-mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  transform-origin: 0 0;
  transition: transform 0.8s cubic-bezier("easeOutQuart");

  html.ready .is-inview .lazyloaded + & {
    transform: scaleY(0);
  }
}

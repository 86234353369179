.custom-checkbox {
  .custom-control-label {
    &:before {
      border-radius: 50%;
    }
  }
  .custom-control-input:checked~.custom-control-label:after {
    background-position: 70% 60%;
    background-size: 80%;
    background-image: url(svg("check", "fill: none; stroke: #1a1818; stroke-width: 3px"));
  }
}

.custom-control-label {
  font-weight: 400;
  a {
    color: currentColor;
  }
}

.swiper-pagination-bullet {
  border: 2px solid $color-accent;
  background-color: $color-accent;
  opacity: .5;
  width: 10px;
  height: 10px;
}

.swiper-pagination-bullet-active {
  background: transparent;
  opacity: .5;
}

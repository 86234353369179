.Logo {
  position: relative;
  @include poly-fluid-sizing('width', $logo-size);
  @include poly-fluid-sizing('height', $logo-size);
}

.Logo-mark {
  position: absolute;
  transform: translateZ(0);
  width: 100%;
  height: 100%;

  path, polygon, rect {
    fill: #fff;
  }
  circle {
    fill: currentColor;
  }

  &-charger {
    transition: transform .15s;
    .desktop a:hover & {
      transform: translateY(-2px);
    }
  }

  &-main {
    transition: transform .2s;
    .desktop a:hover & {
      transform: translateY(-4px);
    }
  }

  &-starter {
    transition: transform .3s;
    .desktop a:hover & {
      transform: translateY(-6px);
    }
  }
}

.Logo-type-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.Logo-type {
  position: absolute;
  pointer-events: none;
  fill: currentColor;
  left: 100%;
  top: 50%;
  margin-left: .75rem;
  transform-origin: 0 50%;
  transform: translateY(-50%) translateX(0) scale(1.8);
}

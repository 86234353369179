.bootstrap-select {
  width: 100% !important;
  font-family: $input-font-family;
  font-weight: $input-font-weight;
  .dropdown-toggle {
    appearance: none;
    background-color: $input-bg;
    color: $input-color;
    padding: $input-padding-y $input-padding-x;
    height: $input-height;
    line-height: $input-line-height;
    border: none;
    // border-style: solid;
    // border-color: $input-border-color;
    // border-width: 0 0 $input-border-width 0;
    &:focus {
      outline: 0 !important;
      border-color: $input-focus-border-color;
      background-color: $input-focus-bg;
    }
  }
  // &.show .dropdown-toggle {
  //   border-color: $input-focus-border-color;
  // }
  .dropdown-menu {
    li {
      background-color: hsb(0, 0, 20);
      &.active {
        background-color: hsb(0, 0, 25);
      }
    }
    a.selected span {
      position: relative;
      &:before {
        content: '✓';
        position: absolute;
        margin-left: -1.5em;
      }
    }
  }
}

.MenuCover {
  position: relative;
  background-color: #ccc;
  // z-index: 1;
  @include prop-res("margin-left", $grid-container-paddings, -1);
  @include prop-res("margin-right", $grid-container-paddings, -1);
  height: 100vw;
  margin-bottom: 5rem;
  @include media-breakpoint-up(md) {
    height: 100vh;
  }
}

.MenuCover-figure,
.MenuCover-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.MenuCover-image {
  background-size: cover;
  background-position: center;
}

.MenuCover-cta {
  position: absolute;
  bottom: 1rem;
  left: 0;
  right: 0;
  text-align: center;
  color: color("turtledove");
  @extend .h5;
}

.X {
  fill: none;
  stroke: currentColor;
  stroke-width: 1.8px;
  transform: translateZ(0);
  @include poly-fluid-sizing('width', $arrow-size);
  @include poly-fluid-sizing('height', $arrow-size);
  cursor: pointer;
  .desktop & g {
    opacity: .6;
    transform-origin: center;
    transition: opacity .6s, transform .6s;
  }
  line {
    transition: transform .6s;
    transform-origin: center;
  }
  .desktop a:hover &,
  .desktop button:hover &{
    g {
      opacity: 1;
      transform: rotate(45deg);
    }
    .X-2 {
      transform: rotate(-45deg) scaleY(0);
    }
  }

}

.X-1 {
  transform: rotate(45deg);
}
.X-2 {
  transform: rotate(-45deg);
}

.X-bg {
  .desktop & {
    opacity: .6;
    transition: opacity .8s;
    transition-delay: .2s;
  }
  .desktop a:hover &,
  .desktop button:hover & {
    opacity: 0;
    transition-delay: 0s;
  }
}

.X-progress {
  transform: rotate(0);
  transform-origin: 50% 50%;
  stroke-dashoffset: 201;
  transition: transform .6s, stroke-dashoffset .8s cubic-bezier("easeInOutQuart");
  stroke-dasharray: 201;
  a:hover &,
  button:hover & {
    stroke-dashoffset: 0;
    transform: rotate(180deg);
  }
}

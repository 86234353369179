.Datepicker {
  text-align: center;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  @include media-breakpoint-down(xs) {
    // position: absolute;
    // width: 100%;
    // height: 100%;
    // display: flex;
    // flex-direction: column;
  }

  .fa {
    // fix fontawesome when loaded by Tracy
    &:before {
      display: none;
    }
  }

}

.Datepicker-main {
  @include media-breakpoint-down(xs) {
    padding: 0 calc(#{map_get($grid-container-paddings, xs)} + 20px) 2rem;
    height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    order: 2;
  }
}

.Datepicker-view {

  padding-right: 1px;
  // hide time switch
  .picker-switch.accordion-toggle {
    display: none;
  }

  .table {
    width: auto;
  }

  table {
    margin: 0;
  }

  .table-sm {
    // width: 80vw;
    // height: 80vh;
    // max-width: 800px;
    // max-height: 800px;
  }

  // months titles
  table .picker-switch {
    pointer-events: none;
    @include ms-respond(font-size, 23);
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;
    -webkit-text-stroke: $text-stroke-width;
    padding: 0;
  }

  td {
    // width: 100 / 7 * 1%;
    // border-collapse: separate;
    // border-width: 2px;
    // border-spacing: 5px;
  }

  th.next,
  th.prev {
    position: relative;
    cursor: pointer;
    span {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transform-origin: 50% 50%;
      background-repeat: no-repeat;
      background-position: center;
      $svg : svg("chevron-down", "fill: none; stroke: #000; stroke-width: 1.1;");
      background-image: url($svg);
      background-size: 30%;
      transform: rotate(-90deg);
      transition: transform .6s;
      @include media-breakpoint-down(xs) {
        background-size: 40%;
      }
    }
    .desktop &:hover span {
      transform: translateX(.5rem) rotate(-90deg);
    }

    &.disabled {
      cursor: default;
      opacity: .4;
    }
  }

  th.prev {
    span {
      transform: rotate(90deg);
    }
    .desktop &:hover span {
      transform: translateX(-.5rem) rotate(90deg);
    }
  }


  .table {
    thead {
      th {
        border: none;
      }
    }
  }

  .dow {
    font-family: $font-family-sans-serif;
    padding: 2rem 0 1rem;
    font-weight: 700;
    font-size: 1rem;
    // color: $color-text-muted;
  }

  .day {
    width: 2.5rem;
    height: 2.5rem;
    @media (min-width: 500px) {
      width: 3rem;
      height: 3rem;
    }
    @media (min-width: 768px) and (min-height: 650px) {
      width: 4rem;
      height: 4rem;
    }
    @media (min-width: 1920px) {
      width: 4.5rem;
      height: 4.5rem;
    }

    font-family: $font-family-sans-serif;
    border: $table-border-width solid $table-border-color;
    padding: 0;
    font-size: 1rem;
    position: relative;
    vertical-align: middle;
    z-index: 1;

    &:before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      background-color: $color-accent;
      transition: opacity .2s, background-color .2s;
      z-index: -1;
    }

    &.old,
    &.new {
      color: rgba($color-text, .4);
    }

    &.disabled {
      color: rgba($color-text, .4);
      background-color: rgba(0,0,0,.03);
    }

    &:not(.disabled) {
      cursor: pointer;
      .desktop &:hover:before {
        opacity: .4;
      }
    }

    &.active {
      color: #fff;
      &:before {
        background-color: #000;
        border: none;
        opacity: 1 !important;
      }
      .desktop &:hover:before {
      }
    }
  }

}



.ArticlePreview {

  @include media-breakpoint-up(sm) {

    @for $i from 1 through 4 {
      .swiper-slide:nth-child(#{$i}) & {
        --index: #{$i - 1};
      }
    }

    .Magazine-item:nth-child(even) & {
      --index: 1;
    }
  }
}

.ArticlePreview-link {
  text-decoration: none !important;
}

.ArticlePreview-figure {
  @include spacer-stack(4);

  .HpMagazine & {
    height: 0;
    padding-bottom: 100%;
  }
}

.ArticlePreview-bg {
  display: none;

  .HpMagazine & {
    display: block;
  }
}

.ArticlePreview-image {
  width: 100%;
  height: auto;
  .HpMagazine & {
    display: none;
  }
}

.ArticlePreview-date {
  @include spacer-stack(2);
  color: color("turtledove");
}

.ArticlePreview-title {
  margin-bottom: 0;
}

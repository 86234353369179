.SplitLines {
  --index: 0;
  --stagger: 0.1s;

  .line {
    opacity: 0;
    transform: translateY(2rem);
    transition: transform 0.8s cubic-bezier('easeOutQuart'), opacity 0.6s;
    transition-delay: calc(var(--index) * var(--stagger));
  }

  html.ready &.is-inview .line,
  html.ready .is-inview & .line {
    transform: none;
    opacity: 1;
  }
}


